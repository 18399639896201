@import "../base/variables";

h1, h2, h3, h4, hr  {
  margin: 0;
}

body {
  font: 400 14px/19px $mainFontRegular;
  color: $mainText;
  margin: 0;

  @media (orientation: landscape) {
    -webkit-text-size-adjust: none;
  }
}


.mb20 {
  margin-bottom: 20px;
  display: block;
}

hr {
  width: 100%;
  margin-left: 0;
  border: 1px solid $lineColor;
}