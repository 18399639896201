@import "../base/variables";

.title {
  font-family: $mainFontRegular;
  color: $mainAccent;

  &--h1 {
    font: 600 26px/35px $mainFontSemiBold;
    margin-bottom: 40px;
    text-align: center;
  }

  &--h2 {
    font: 600 20px/27px $mainFontSemiBold;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  &--h3 {
    font: 700 20px/27px $mainFontSemiBold;
  }

  &--h4 {
    font: 600 16px/21px $mainFontSemiBold;
    color: $mainText;
    margin-bottom: 10px;
  }
}