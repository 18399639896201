@import "../base/variables";

.subtitle {
  margin-bottom: 38px;
  font: 400 16px/21px $mainFontRegular;
  font-style: italic;
  display: block;

  &--image {
    text-align: center;
  }
}