@import "../base/variables";

.toggle {
  cursor: pointer;
}

.toggle__label {
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: 1px solid $mainAccent;
  border-radius: $borderRadiusCircle;
  display: inline-block;
  box-sizing: border-box;

  &::before {
    transform: translate(0, -50%) rotate(-90deg);
    will-change: translate, rotate;

    .active-button & {
      transform: translate(0, -50%) rotate(-0deg);
      width: 0;
    }
  }

  &::after {
    transform: translate(0, -50%);
    will-change: translate, opacity;
  }

  &::after,
  &::before {
    content: "";
    width: 14px;
    height: 1px;
    border-radius: 2px;
    background-color: $toggleColor;
    display: block;
    position: absolute;
    transition: all .2s ease-in-out;
    opacity: 1;
    contain: strict;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.toggle {
  &__wrapper {
    display: grid;
    overflow: hidden;
    transition: grid-template-rows .3s;
    grid-template-rows: 0fr;
    grid-gap: 10px;

    &.active {
      grid-template-rows: 1fr;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 0;
    gap: 10px;
  }
}