@import "../base/variables";

.text {
  &--indent {
    margin-top: 0;
    margin-bottom: 20px;
  }

  &--inline {
    display: inline;
    padding-bottom: 20px;
  }

  &--bg {
    background-color: $mainBg;
    padding: 40px;
  }

  &--bold {
    font-weight: bold;
  }

  &--italic {
    font-style: italic;
  }

  &--error {
    color: $error;
  }

  &--correct {
    color: $correct;
  }
}