@import "../base/variables";

.faq {
  background-color: $mainBg;
  padding: 20px;
}

.faq__wrapper {
  max-width: 1400px;
  padding: 60px 0;
  background-color: $white;
  margin: 0 auto;
  border-radius: $borderRadiusCommon;
}

.faq__section {
  position: relative;
  padding-inline: 40px;

  @media (max-width: 767px) {
    padding-inline: 20px;
  }
}

.faq__section-content {
  position: relative;
  max-height: 0;
  text-align: justify;
  overflow: hidden;
  transition: max-height .3s linear;
}

.faq__video {
  position: relative;
  height: 0;
  padding-top: 0;
  padding-bottom: 56.25%;
  text-align: center;
}

.faq__video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.faq__list {
  padding: 0;
  list-style-position: inside;

  & + p {
    margin-top: 20px;
  }

  &--inner {
    padding-left: 8px;
  }
}

.faq__list-item {
  background-color: $white;

  &--nomarker {
    list-style: none;
  }

  &--numbered {
    margin-bottom: 20px;

    h3 {
      position: relative;
      display: inline;
    }

    &::marker {
      font-weight: bold;
    }
  }

  &--unnumbered {
    position: relative;
    font: 400 14px/19px $mainFontRegular;
    color: $mainText;
    list-style-type: initial;

    &:not(:last-child) {
      margin-bottom: 20px;
      padding-bottom: 0;
    }
  }
}

.faq__link {
  text-decoration: none;
  color: $mainAccent;

  &:hover {
    text-decoration: underline;
  }
}

.faq__image {
  max-width: 100%;
  //height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;

  &--indent {
    margin-block: 20px;
  }
}

.faq__image-container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;

  img {
    width: 50%;
  }
}