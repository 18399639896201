@import "variables";

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../fonts/OpenSans.woff2") format("woff2"),
  url("../../fonts/OpenSans.woff") format("woff");
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../fonts/OpenSans-SemiBold.woff2") format("woff2"),
  url("../../fonts/OpenSans-SemiBold.woff") format("woff");
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../fonts/OpenSans-Bold.woff2") format("woff2"),
  url("../../fonts/OpenSans-Bold.woff") format("woff");
}